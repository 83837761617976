import { Stack, Typography } from "@mui/material";
import HealthMeasurementDetailVerificationStatus from ".";
import { HealthProfileItemDataSource } from "../../../../services/core-api-adapter";

export default function HealthMeasurementVerificationStatusExamples() {
  return (
    <Stack padding={2} spacing={2}>
      <Typography>Verified status</Typography>
      <HealthMeasurementDetailVerificationStatus
        healthMeasurementDetailMetaData={[
          {
            shouldDisplay: false,
            questionType: "SmokingFrequency",
            isVerified: true,
            lastUpdated: "2024-09-16T13:42:36.521883+00:00",
            originatedFrom: "BINAH",
          },
        ]}
      />

      <HealthMeasurementDetailVerificationStatus
        healthMeasurementDetailMetaData={[
          {
            shouldDisplay: false,
            questionType: "SmokingFrequency",
            isVerified: true,
            lastUpdated: "2022-09-16T13:42:36.521883+00:00",
            originatedFrom:
              HealthProfileItemDataSource.HEALTH_CLOUD_ABBY_STATION,
          },
        ]}
      />

      <Typography>Unverified status</Typography>
      <HealthMeasurementDetailVerificationStatus
        healthMeasurementDetailMetaData={[]}
      />
      <HealthMeasurementDetailVerificationStatus
        healthMeasurementDetailMetaData={[
          {
            shouldDisplay: false,
            questionType: "SmokingFrequency",
            isVerified: false,
            lastUpdated: "2022-09-16T13:42:36.521883+00:00",
          },
        ]}
      />
    </Stack>
  );
}
